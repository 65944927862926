@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;600;700&display=swap');

@layer base {
    html {
        scroll-behavior: smooth;
    }
}

::-webkit-scrollbar {
    width: 0.4em;
}

::-webkit-scrollbar-track {
    background: #0A192F;
}

::-webkit-scrollbar-thumb {
    background: #5a6075;
    border-radius: 100vw;
}